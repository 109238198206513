<template>
  <div class="page-xq">
    <div class="scrbox">
      <div class="wp1200">
        <div
          class="m-detail_ue"
          :class="!show ? 'minh' : ''"
          v-if="detailsData"
        >
          <div class="tit" v-if="detailsData.title">
            {{ detailsData.title }}
          </div>
          <!-- <div class="imgs">
            <img
              v-for="(item, index) of detailsData.sub"
              :key="index"
              v-lazy="item"
              alt=""
            />
          </div> -->
          <div class="txt">
            <!-- <p style="text-align: center;" v-if="detailsData.image"><img :src="detailsData.image" alt=""></p> -->
            <div v-html="detailsData.desc"></div>
          </div>
        </div>
        <el-empty class="pop-emp" v-else description="暂无数据"></el-empty>

        <div class="page-btns work">
          <div class="btn prev" @click="gotopage(pre_id)" v-if="pre_id != 0">
            <i class="el-icon-arrow-left"></i><span class="s">上一篇作品</span>
          </div>
          <div class="btn next" @click="gotopage(nex_id)" v-if="nex_id != 0">
            <i class="el-icon-arrow-right"></i><span class="s">下一篇作品</span>
          </div>
        </div>
      </div>
      <!-- <div class="back" @click="goback"><i class="iconfont">&#xe600;</i></div> -->
      <div class="wp1200" v-if="show">
        <div class="m-xq-bot">
          <img src="@/assets/images/new/gif.gif" alt="" class="icn" />
          <p>设计合作微信：planificatrice</p>
          <p>Design cooperation WeChat: planificatrice</p>
          <br />
          <p>UIUX设计，网站建设，品牌设计，平面设计</p>
          <p>
            UIUX design, website development, branding design, graphic design
          </p>
        </div>
      </div>
    </div>

    <!-- 密码弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      custom-class="n-pop"
      :close-on-click-modal="false"
    >
      <div class="n-form">
        <div class="tit">输入密码查看</div>
        <img src="@/assets/images/new/n-p1.png" alt="" class="bg" />
        <div class="gp">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="0px"
            class="demo-ruleForm"
          >
            <el-form-item label="" prop="password" ref="pwdinp">
              <el-input
                type="password"
                v-model="ruleForm.password"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="bot">
          <div class="btn" @click="cancel">取消</div>
          <div class="btn btn1" @click="checkPwd">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Loading } from 'element-ui';
import $ from 'jquery'
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value !== this.alpwd) {
        callback(new Error('密码错误'));
      } else {
        callback();
      }
    }
    return {
      detailsData: {},
      target_content: {},
      clickid: '',
      pre_id: '',
      nex_id: '',

      show: false,

      haslogin: false,//是否从首页验证过


      dialogVisible: false,

      alpwd: '',
      ruleForm: {
        password: null,
      },
      rules: {
        password: [
          // { validator: validatePass, trigger: 'blur' },
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      },
    }
  },
  mounted () {
    $('.scrbox').scrollTop(0);
    window.scroll(0, 0);

  },
  created () {
    // console.log(this.$route.params, this.$route.query);
    this.clickid = this.$route.params.id || localStorage.getItem('yhgDetailsId');
    this.haslogin = window.localStorage.getItem('yhgworklogin')
    this.showDetail(this.clickid);
    window.localStorage.setItem('yhgworklogin', 0)
  },
  methods: {
    /*  goback () {
       this.$router.go(-1);
     }, */
    gotopage (id) {
      this.clickid = id
      // this.showDetail(id)
      this.$router.push({
        path: '/details/' + id,

      })
    },

    showDetail (id) {
      let that = this

      let data = {
        id: id
      };
      that.$http
        .getDetail(data)
        .then((res) => {
          if (res.ret == 0) {
            // console.log(res);
            let result = res.data
            setTimeout(function () {
              that.show = true
            }, 300);

            that.$nextTick(function () {
              window.scroll(0, 0)
              $('.scrbox').scrollTop(0)
            })

            if (that.haslogin == 1) {
              that.detailsData = result
              that.pre_id = result.pre_id
              that.nex_id = result.nex_id
              return false;
            }
            // flag 1：未加密 2：加密
            if (result.flag == 1) {
              that.detailsData = result
              that.pre_id = result.pre_id
              that.nex_id = result.nex_id

            } else if (result.flag == 2) {
              that.alpwd = result.pwd
              that.ruleForm.password = ''
              that.dialogVisible = true
              that.target_content = result
            }
          } else {

          }
        });

    },
    checkPwd () {
      let that = this
      let data = {
        id: that.clickid,
        pwd: that.ruleForm.password
      };
      that.$http
        .checkPwd(data)
        .then((res) => {
          if (res.ret == 0) {

            that.$message({
              message: '验证成功！',
              type: 'success',
              duration: 1000
            });
            setTimeout(() => {
              that.dialogVisible = false
              that.detailsData = that.target_content
              that.pre_id = that.target_content.pre_id
              that.nex_id = that.target_content.nex_id
            }, 1200);

          } else {
            that.$message({
              message: res.msg,
              type: 'warning',
              duration: 1000
            });
          }
        })
      


    },

    cancel () {
      this.dialogVisible = false
      this.ruleForm.password = ''
      this.$router.go(-1)
    },
  },

  watch: {
    // 如果路由发生变化，再次执行该方法
    $route (to, from) {
      // console.log(to.params.id);
      this.showDetail(to.params.id)
    }
  }


}
</script>

<style >
</style>